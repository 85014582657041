import { ALOE_100, CHARCOAL_100, MANGO_40, MOSS_100 } from '@rategravity/own-up-component-library';
import { colors as libraryColors } from '@rategravity/own-up-component-library-legacy';

export const colors = {
  ...libraryColors,
  ALOE_100,
  AZURE: '#00769B' as const,
  CHARCOAL: '#1D1D1F' as const,
  CHARCOAL_100,
  CHART_BLUE_ONE: '#D7DFFB' as const,
  CHART_BLUE_TWO: '#C2CEF9' as const,
  CHART_BLUE_THREE: '#AEBEF7' as const,
  CHART_BLUE_FOUR: '#9AAEF5' as const,
  CHART_GREEN: '#3E5351' as const,
  EUCALYPTUS_TWO: '#D3E8DE' as const,
  LIGHT_BLUE: '#E2F4F8' as const,
  LIGHT_GREY: '#B2C1C0' as const,
  LIGHT_GREY_TWO: '#E5E5E5' as const,
  DARK_GREY: '#343435' as const,
  MANGO: '#FFE485' as const,
  MANGO_40: MANGO_40,
  MINT: '#B9DDCC' as const,
  MOSS_100: MOSS_100,
  PINE: '#1E4C49' as const,
  PIMENTO: '#FAE0D5' as const,
  SAGE: '#DDECE5' as const,
  SLATE: '#657383' as const,
  SLATE_TWO: '#0E2826' as const,
  FLORAL_WHITE: '#F8F7F4' as const
};
