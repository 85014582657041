/**
 * Takes in a scenario that could use various formats, and returns
 * a standardized format.
 *
 * Returns undefined if the scenario is not recognized.
 *
 * @param scenario - The scenario name to standardize.
 */

export const standardizeScenario = (
  scenario?: string
): 'Preapproval' | 'AcceptedOffer' | 'Purchase' | 'Refi' | 'RefiCashOut' | undefined => {
  if (!scenario) {
    return undefined;
  }
  switch (scenario.toLocaleLowerCase().replace('-', '')) {
    case 'preapproval':
      return 'Preapproval';

    case 'acceptedoffer':
    case 'acceptedofferfull':
      return 'AcceptedOffer';

    case 'purchase':
    case 'purchasefull':
    case 'quote':
    case 'qualify':
      return 'Purchase';

    case 'refi':
    case 'refinance':
    case 'refinancefull':
    case 'refifull':
    case 'rateterm':
    case 'refiquote':
    case 'refiqualify':
      return 'Refi';

    case 'reficashout':
    case 'cashoutrefinance':
    case 'cashoutrefinancefull':
    case 'cashout':
      return 'RefiCashOut';

    default:
      return undefined;
  }
};

export type StandardizedScenarioType = ReturnType<typeof standardizeScenario>;

/**
 *  Takes in a scenario and returns the appropriate questionnaireType value
 */
export const mapScenarioToQuestionnaireType = (scenario?: string): string => {
  switch (scenario) {
    case 'Refi':
    case 'RefiQuality':
    case 'RefiFull':
    case 'RefiCashOut':
    case 'Refinance':
    case 'refinance':
      return 'refinance';
    case 'AcceptedOffer':
    case 'accepted-offer':
      return 'accepted-offer';
    case 'Purchase':
    case 'purchase':
      return 'purchase';
    case 'Preapproval':
    case 'preapproval':
      return 'preapproval';
    default:
      return 'general';
  }
};
