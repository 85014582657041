import { maybeGetToken } from '../../authentication/modules/token-storage';

let ownUpLoginToken: string | null = null;

export const getLoginToken = (redirect: boolean = true) => {
  if (!ownUpLoginToken) {
    ownUpLoginToken = maybeGetToken().orElse(() => {
      // Redirect to www homepage if no token
      if (redirect) {
        window.location.href = `https://${process.env.DEPLOY_ENV == 'staging' ? 'staging.' : ''}www.ownup.com/`;
      }
      return '';
    });
  }
  return ownUpLoginToken;
};
